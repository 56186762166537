import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home';

const App = () => {
  useEffect(() => {
    let url = typeof window !== 'undefined' ? window.location.href : ''; // 현재 url
    let ref =
      typeof document !== 'undefined'
        ? typeof document.referrer !== 'undefined'
          ? document.referrer
          : ''
        : ''; // 이전 페이지
    let title = typeof document !== 'undefined' ? document.title : '';
    const pageViewData = {
      pageUrl: url,
      pageRef: ref,
      pageTitle: title,
    };

    window.Lillo?.init('43e88da004ced524ae45801e2171c943');

    window.LilloHook?.on('init', function () {
      window.Lillo?.pageView(pageViewData);
    });
  }, []);
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/" exact={true} element={<Home />} />
      </Routes>
    </>
  );
};

export default App;
