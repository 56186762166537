import styled from 'styled-components';

export const Wrapper = styled.div`
	* {
		box-sizing: border-box;
	}
	background: #f7f7f7;
	.container {
		max-width: 600px;
		min-height: 100vh;
		margin: 0px auto;
		background: #fff;
		position: relative;
		// a {
		//     color: #fb4700;
		//     text-decoration: underline;
		// }
	}
	.header {
		position: sticky;
		top: 0;
		left: 50%;
		width: 100%;
		max-width: 600px;
		z-index: 2;
	}
	.wrap {
		// padding-top: 122px;
		padding-bottom: 56px;
	}
	.line-banner {
		background: #f6f6f2;
		padding: 16px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.innerbox {
			padding-left: 8px;
			h3 {
				font-size: 16px;
				font-weight: 700;
				letter-spacing: -0.48px;
			}
			p {
				font-size: 13px;
				font-weight: 400;
				color: #717171;
				margin-top: 8px;
				letter-spacing: -0.39px;
			}
		}
		.exit-btn {
			color: #fff;
			text-decoration: underline;
			padding: 13px 20px;
			background-color: #fb4700;
			text-decoration: none;
			font-size: 12px;
			font-weight: 500;
			border-radius: 21px;
		}
	}
	.navi-bar {
		display: flex;
		align-items: center;
		flex-direction: row;
		height: 52px;
		padding: 0 16px;
		justify-content: space-around;
		background: #fff;
		.search-box {
			width: 100%;
			margin: 0 12px;
			position: relative;
		}
		.search {
			padding: 9px 34px 10px 16px;
			border: solid 1px #e0e0e0;
			border-radius: 30px;
			width: 100%;
			text-overflow: ellipsis;
		}
		.search::placeholder {
			color: #a9a9a9;
			font-size: 12px;
			text-overflow: ellipsis;
		}
		.search-img {
			position: absolute;
			right: 12px;
			top: 50%;
			transform: translate(0, -50%);
		}
		.inbox {
			width: 24px;
			margin-right: 14px;
		}
	}
	.tab {
		padding: 0 16px;
		display: flex;
		flex-direction: row;
		align-items: center;
		a {
			width: calc(100% / 3);
			text-align: center;
			display: block;
			height: 48px;
			line-height: 48px;
			text-decoration: none;
			color: #999;
			font-weight: 500;
			font-size: 16px;
		}
		a.active {
			color: #000;
			border-bottom: 2px solid #000;
		}
	}
	.banner-container {
		width: 100%;
		position: relative;
		.banner-img {
		}
		.banner-text {
			color: #fff;
			position: absolute;
			bottom: 62px;
			left: 52px;
			z-index: 1;
			h2 {
				color: #fff;
				font-size: 38px;
				font-family: Noto Sans KR;
				font-style: normal;
				font-weight: 700;
				line-height: 130%;
				letter-spacing: -1.14px;
			}
			p {
				color: rgba(255, 255, 255, 0.8);
				font-size: 18px;
				font-family: Pretendard;
				font-style: normal;
				font-weight: 500;
				line-height: 120%;
				margin-top: 23px;
			}
		}
	}
	.banner-img {
		width: 100%;
	}
	.banner-container:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0.48) 100%
		);
	}
	.category {
		margin: 16px 0 16px 16px;
		.catelist {
			display: flex;
			flex-direction: row;
			gap: 12px;
			overflow-x: scroll;
			-ms-overflow-style: none;
			scrollbar-width: none;
			.cate-container {
				display: flex;
				flex-direction: column;
				gap: 8px;
				align-items: center;
				.imgbox {
					width: 56px;
					height: 56px;
					background-color: #f9f9f9;
					border-radius: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.imgbox.first {
					background-color: #fff;
					border: 1px solid #e9e9e9;
				}
				.imgbox.second {
					background-color: #000;
					position: relative;
					.badge {
						position: absolute;
						top: 0;
						right: 0;
					}
				}
				span {
					text-decoration: none;
					color: #000;
					font-size: 12px;
					font-weight: 500;
					line-height: 14px;
				}
			}
		}
		.catelist::-webkit-scrollbar {
			display: none;
		}
		.cate-bar {
			width: 2px;
			height: 54px;
			background-color: #f9f9f9;
		}
	}
	.contents {
		padding: 0 16px;
		display: flex;
		flex-wrap: wrap;
	}
	.product {
		width: 31.5%;
		margin-right: 2.75%;
		margin-bottom: 30px;
	}
	.product:nth-child(3n) {
		margin-right: 0px;
	}
	.prod-img-box {
		position: relative;
		.prod-img {
			border-radius: 12px;
			width: 100%;
		}
		.heart {
			position: absolute;
			bottom: 16px;
			right: 16px;
			z-index: 1;
		}
	}
	.prod-text-box {
		i {
			margin-top: 8px;
			color: #666;
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 14px;
			display: block;
		}
		p {
			color: #000;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: 14px;
			margin: 6px 0 8px 0;
		}
		.prod-price {
			strong {
				color: #fb4700;
				font-size: 15px;
				font-style: normal;
				font-weight: 700;
				line-height: 14px;
				margin-right: 6px;
			}
			span {
				font-size: 15px;
				font-style: normal;
				font-weight: 500;
				line-height: 14px;
			}
		}
	}
	.footer {
		width: 100%;
		max-width: 600px;
		height: 56px;
		border: 1px solid #e9e9e9;
		border-bottom: none;
		border-radius: 16px 16px 0 0;
		position: fixed;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		background: #fff;
		.flex {
			display: flex;
		}
		.menu {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 25%;
			padding: 14px 0 6px 0;
			gap: 3px;
		}
		img {
			width: 20px;
		}
		p {
			color: #999;
			text-align: center;
			font-size: 11px;
			font-family: Pretendard;
			font-style: normal;
			font-weight: 500;
			line-height: 14px;
		}
		p.active {
			color: #000;
		}
	}

	@media (max-width: 480px) {
		.banner-container {
			.banner-text {
				bottom: 40px;
				left: 20px;
				h2 {
					font-size: 24px;
				}
				p {
					font-size: 14px;
					margin-top: 15px;
				}
			}
		}
		.product {
			width: 48%;
			margin-right: 4%;
			margin-bottom: 30px;
		}
		.product:nth-child(3n) {
			margin-right: 4%;
		}
		.product:nth-child(2n) {
			margin-right: 0;
		}
	}
`;
