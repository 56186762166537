import React from 'react';
import { Wrapper } from './index.styled';
import { toast } from 'react-toastify';

const Home = () => {
	const doDemoToast = () => {
		toast.info(
			<>
				릴로 서비스 데모 체험용 쇼핑몰이에요. 데모 체험 외 기능은 이용할 수
				없어요.
			</>
		);
	};

	const products = [
		{
			thumbnail: 'images/prod_01.png',
			name: '진정 수분 선크림',
			discount: '30%',
			price: '28,000',
		},
		{
			thumbnail: 'images/prod_02.png',
			name: '[증정] 저자극 클렌징 오일',
			discount: '25%',
			price: '13,500',
		},
		{
			thumbnail: 'images/prod_03.png',
			name: '그린티 히알루론산 토너',
			discount: '20%',
			price: '24,000',
		},
		{
			thumbnail: 'images/prod_04.png',
			name: '트러블 시카 앰플',
			discount: '20%',
			price: '24,000',
		},
		{
			thumbnail: 'images/prod_05.png',
			name: '티트리 엑티브 스킨 기획 세트',
			discount: '30%',
			price: '28,000',
		},
		{
			thumbnail: 'images/prod_06.png',
			name: '퓨어 클리어 퍼퓸 바디워시',
			discount: '25%',
			price: '13,500',
		},
		{
			thumbnail: 'images/prod_03.png',
			name: '그린티 히알루론산 토너',
			discount: '20%',
			price: '24,000',
		},
		{
			thumbnail: 'images/prod_01.png',
			name: '진정 수분 선크림',
			discount: '30%',
			price: '28,000',
		},
		{
			thumbnail: 'images/prod_02.png',
			name: '[증정] 저자극 클렌징 오일',
			discount: '25%',
			price: '13,500',
		},
		{
			thumbnail: 'images/prod_05.png',
			name: '티트리 엑티브 스킨 기획 세트',
			discount: '30%',
			price: '28,000',
		},
		{
			thumbnail: 'images/prod_06.png',
			name: '퓨어 클리어 퍼퓸 바디워시',
			discount: '25%',
			price: '13,500',
		},
		{
			thumbnail: 'images/prod_04.png',
			name: '트러블 시카 앰플',
			discount: '20%',
			price: '24,000',
		},
	];

	const category = [
		{
			icon: 'images/top.svg',
			text: '상의',
		},
		{
			icon: 'images/skirt.svg',
			text: '하의',
		},
		{
			icon: 'images/onepiece.svg',
			text: '원피스',
		},
		{
			icon: 'images/bag.svg',
			text: '가방',
		},
		{
			icon: 'images/shoe.svg',
			text: '신발',
		},
		{
			icon: 'images/pants.svg',
			text: '바지',
		},
		{
			icon: 'images/skirt.svg',
			text: '하의',
		},
	];

	return (
		<Wrapper>
			<div className="container">
				<header className="header">
					<div className="line-banner">
						<div className="innerbox">
							<h3>릴로 데모용 쇼핑몰입니다.</h3>
							<p>화면 하단의 안내를 따라 릴로를 체험하세요.</p>
						</div>
						<a className="exit-btn" href="https://lillo.co.kr/membership">
							체험 종료
						</a>
					</div>
					<nav className="navi-bar">
						<a href="javascript:void(0);" onClick={() => doDemoToast()}>
							{/* <a href="javascript:void(0);" onClick={doDemoToast}> */}
							<img src="images/logo.svg"></img>
						</a>
						<div className="search-box" onClick={() => doDemoToast()}>
							<input
								className="search"
								placeholder="검색어를 입력해주세요."
								readOnly
							></input>
							<img className="search-img" src="images/search.svg"></img>
						</div>
						<a className="inbox" href="#">
							<img src="images/inbox.svg"></img>
						</a>
						<a
							className="cart"
							href="javascript:void(0);"
							onClick={() => doDemoToast()}
						>
							<img src="images/cart.svg"></img>
						</a>
					</nav>
				</header>
				<section className="wrap">
					<div className="tab">
						<a
							className="active"
							href="javascript:void(0);"
							onClick={() => doDemoToast()}
						>
							홈
						</a>
						<a href="javascript:void(0);" onClick={() => doDemoToast()}>
							브랜드
						</a>
						<a href="javascript:void(0);" onClick={() => doDemoToast()}>
							추천
						</a>
					</div>
					<section className="main-section">
						<div className="banner-container">
							<img className="banner-img" src="images/main-banner.png"></img>
							<div className="banner-text">
								<h2>
									릴로 푸시알림
									<br />
									체험용 쇼핑몰입니다.
								</h2>
								<p>화면 하단의 안내를 따라 릴로를 체험하세요</p>
							</div>
						</div>
					</section>
					<div className="category">
						<ul className="catelist">
							<li>
								<a href="javascript:void(0);" onClick={() => doDemoToast()}>
									<div className="cate-container">
										<div className="imgbox first">
											<img src="images/category.svg"></img>
										</div>
										<span>카테고리</span>
									</div>
								</a>
							</li>
							<li>
								<div className="cate-bar"></div>
							</li>
							<li>
								<a href="javascript:void(0);" onClick={() => doDemoToast()}>
									<div className="cate-container">
										<div className="imgbox second">
											<img src="images/lillo.svg"></img>
											<img className="badge" src="images/new.svg"></img>
										</div>
										<span>릴로</span>
									</div>
								</a>
							</li>
							{category
								? category.map((item, key) => (
										<li key={key}>
											<a
												href="javascript:void(0);"
												onClick={() => doDemoToast()}
											>
												<div className="cate-container">
													<div className="imgbox">
														<img src={item.icon}></img>
													</div>
													<span>{item.text}</span>
												</div>
											</a>
										</li>
								  ))
								: null}
						</ul>
					</div>
					<div className="contents">
						{products
							? products.map((prod, key) => (
									<a
										href="javascript:void(0);"
										onClick={() => doDemoToast()}
										className="product"
										key={key}
									>
										<div className="prod-img-box">
											<img className="prod-img" src={prod.thumbnail}></img>
											<img className="heart" src="images/heart.svg"></img>
										</div>
										<div className="prod-text-box">
											<i>릴로</i>
											<p>{prod.name}</p>
											<div className="prod-price">
												<strong>{prod.discount}</strong>
												<span>{prod.price}</span>
											</div>
										</div>
									</a>
							  ))
							: null}
					</div>
				</section>
				<footer className="footer">
					<div className="flex">
						<a
							href="javascript:void(0);"
							onClick={() => doDemoToast()}
							className="menu"
						>
							<img src="images/footer_01.svg"></img>
							<p className="active">홈</p>
						</a>
						<a
							href="javascript:void(0);"
							onClick={() => doDemoToast()}
							className="menu"
						>
							<img src="images/footer_02.svg"></img>
							<p>카테고리</p>
						</a>
						<a
							href="javascript:void(0);"
							onClick={() => doDemoToast()}
							className="menu"
						>
							<img src="images/footer_03.svg"></img>
							<p>찜</p>
						</a>
						<a
							href="javascript:void(0);"
							onClick={() => doDemoToast()}
							className="menu"
						>
							<img src="images/footer_04.svg"></img>
							<p>마이페이지</p>
						</a>
					</div>
				</footer>
			</div>
		</Wrapper>
	);
};

export default Home;

{
	/* <a href="javascript:void(0);" onClick={() => doDemoToast()}>
    토스트 띄우기
</a> */
}
